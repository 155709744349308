<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import transferIn from '@/api/drugTransfer/transferIn'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, sumdate, sumdatetime } from '@/plugins/filters'
import 'jspdf-autotable'
import shop from '@/api/shop'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(14)
    const fontBody = ref(13)
    const frame = ref('')

    shop.shop().then(res1 => {
      transferIn.transferInGet(
        route.value.query.id,
      ).then(res2 => {
        savePDF(res2.data, res1)
      })
    })

    const headers = [
      i18n.t('code'),
      i18n.t('list'),
      i18n.t('expire_date'),
      i18n.t('amount'),
      i18n.t('unit'),
      i18n.t('price_unit'),
      i18n.t('amount_money'),
    ]
    const generateData = data => {
      const result = []
      data.forEach(item => {
        result.push([
          item.transferorder_drug_id,
          item.transferorder_drug_name,
          sumdate(item.transferorder_drugorder_expdate),
          formatPrice(item.transferorder_amount),
          item.transferorder_drug_unit,
          formatPrice(item.transferorder_price),
          formatPrice(item.transferorder_total),
        ])
      })

      return result
    }
    const savePDF = (data, shop) => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
      })
      doc.setProperties({
        title: 'ใบตรวจรับยา / อุปกรณ์',
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      const spacer = 5
      let total = 10
      let totalPirce = 0

      const listdata = generateData(data.transferorder)
      listdata.forEach(element => {
        totalPirce += +element[6].replaceAll(',', '')
      })

      const footer = [
        {
          content: 'รวม',
          colSpan: 6,
          styles: {
            halign: 'center',
          },
        },
        formatPrice(totalPirce),
      ]
      dataTableList.value = data.check_list

      doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 7, 6, 28, 26)

      doc.setFontSize(fontTitle.value)
      doc.setFillColor('#212121')
      doc.text(`${shop.shop_name} ${shop.shop_nature}`, 40, total)
      doc.setFont('TH-Niramit', 'normal')
      total += spacer
      doc.text(`${shop.shop_address} ${shop.shop_district}`, 40, total)
      total += spacer
      doc.setFontSize(16)
      doc.text('ใบตรวจรับยา / อุปกรณ์', 205, total - 5, null, null, 'right')
      doc.text(`${i18n.t('a_number')} ${data.transfer_id}`, 205, total + 2, null, null, 'right')
      doc.setFontSize(fontTitle.value)
      doc.text(`${shop.shop_amphoe} ${shop.shop_province} ${shop.shop_zipcode}`, 40, total)
      total += spacer
      doc.text(`เบอร์โทรศัพท์ ${shop.shop_tel} ${i18n.t('email')} ${shop.shop_email}`, 40, total)
      total += spacer
      doc.text(`${i18n.t('numberLicenses')} ${shop.shop_license} ${i18n.t('tax_id')} ${shop.shop_tax}`, 40, total)
      total += spacer
      doc.setLineWidth(0.8)
      doc.setDrawColor('#1E88E5')
      doc.line(61, 34, 205, 34)
      doc.setDrawColor('#212121')
      doc.line(4, 34, 60, 34)

      total += spacer
      doc.setFont('TH-Niramit', 'bold')
      doc.text(`${i18n.t('list')}`, 5, total)
      doc.setFont('TH-Niramit', 'normal')
      doc.text(`(โอนมาจาก : ${data.shop_id_name})`, 20, total)
      doc.setFont('TH-Niramit', 'normal')
      doc.text(`${i18n.t('date')} : ${sumdatetime(data.transfer_create)}`, 205, total, null, null, 'right')
      total += spacer

      doc.autoTable({
        theme: 'grid',
        styles: {
          font: 'TH-Niramit',
          fontStyle: 'normal',
          textColor: '#212121',
          fontSize: fontBody.value,
          halign: 'left',
        },
        showFoot: 'lastPage',
        headStyles: {
          textColor: '#fff',
          fillColor: '#1E88E5',
          fontStyle: 'bold',
          lineWidth: 0.1,
          fontSize: fontTitle.value,
          halign: 'center',
        },
        footStyles: {
          textColor: '#212121',
          fillColor: '#fff',
          fontStyle: 'bold',
          lineWidth: 0.1,
          fontSize: fontBody.value,
          halign: 'right',
        },
        columnStyles: {
          0: { halign: 'center', cellWidth: 18 },
          1: { halign: 'left', fillColor: '#F5F5F5' },
          2: { halign: 'center', cellWidth: 24 },
          3: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 15 },
          4: { halign: 'center', cellWidth: 15 },
          5: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 25 },
          6: { halign: 'right', cellWidth: 25 },
        },
        startY: 41,
        margin: { left: 5, right: 5, bottom: 10 },
        head: [headers],
        body: generateData(data.transferorder),
        foot: [footer],

        didDrawPage(datax) {
          total = datax.cursor.y
        },
      })
      total += spacer
      doc.setFont('TH-Niramit', 'bold')
      doc.text('หมายเหตุ : ', 5, total)
      doc.setFont('TH-Niramit', 'normal')
      doc.text(data.transfer_comment || '', 23, total)
      total += spacer * 2
      doc.text('....................................................................', 52.5, total, null, null, 'center')
      doc.text('....................................................................', 157.5, total, null, null, 'center')
      total += spacer
      doc.text(`(${data.user_fullname})`, 52.5, total, null, null, 'center')
      doc.text('(                                    )', 157.5, total, null, null, 'center')
      total += spacer
      doc.text(`${i18n.t('date')} ${sumdate(data.transfer_create)}`, 52.5, total, null, null, 'center')
      doc.text(`${i18n.t('date')}............./............./.............`, 157.5, total, null, null, 'center')
      frame.value = doc.output('datauristring', { filename: 'test.pdf' })
    }

    return { savePDF, frame }
  },
}
</script>
